.table-image-wrapper {
    width: 80px;
    height: 80px;
}

.image-preview-wrapper {
    width: 150px;
    height: 150px;
}

.fit-contain {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
}

.fixed-top-bar {
    padding-top: 61px;
}