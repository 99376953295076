.toast-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1040;
    width: 300px;
    height: auto;
}

.slide-in {
    width: 280px;
    animation-name: slide-in;
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

.slide-out {
    width: 280px;
    animation-name: slide-out;
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

@keyframes slide-in {
    from {
        margin-left: 500px;
    }

    to {
        margin-left: 0px;
    }
}

@keyframes slide-out {
    from {
        margin-left: 0px;
    }

    to {
        margin-left: 500px;
    }
}