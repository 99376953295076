.custom-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1050;
}

.custom-modal-body {
    padding: 10px;
    background-color: #fff;
    animation: PopUp 0.3s;
    animation-fill-mode: forwards;
}

.custom-modal-pop-down {
    animation: PopDown 0.3s;
    animation-fill-mode: forwards;
}

@keyframes PopUp {
    0% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes PopDown {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(0);
    }
}